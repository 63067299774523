import Login from '../pages/login/login';
import Feedback from '../pages/feedback/feedback';

/**
 * portal
 */
import Portal_property from '../pages/portal_property/portal_property';
import List from '../pages/portal_product_list/portal_product_list';
import Detail from '../pages/portal_product_detail/portal_product_detail';
// import Portal_product_preview_detail from '../pages/portal_product_preview_detail/portal_product_preview_detail';
import Portal_mine from '../pages/portal_mine/portal_mine';
import Portal_account from '../pages/portal_account/portal_account';
/** 官网 */
import Official from '../pages/official/official';
/** 协议 */
import privacy_agreement from '../pages/privacy_agreement/privacy_agreement';
import user_agreement from '../pages/user_agreement/user_agreement';
import LandingPage from '../pages/landingpage';
import BusinessCard from '../pages/businessCard';

/** 需要鉴权，没权的会重定向到/Login */
const ValidatingRoutesList = [
    /**
     * portal
     */
    {
        path: '/mine',
        component: Portal_mine,
        exact: true,
    },
    {
        path: '/history',
        component: Portal_account,
        exact: true,
    },
];

/**
 * 不需要鉴权
 * 但部分通过页面在useEffect的时候，对token进行了判断，进行页面的自定义跳转
 */
const NonValidatingRoutesList = [
    {
        path: '/',
        component: Official,
        exact: true,
    },
    {
        path: '/businesscard',
        component: BusinessCard,
        exact: true,
    },
    {
        path: '/privacy_agreement',
        component: privacy_agreement,
        exact: true,
    },
    {
        path: '/user_agreement',
        component: user_agreement,
        exact: true,
    },
    {
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        path: '/feedback',
        component: Feedback,
        exact: true,
    },
    {
        path: '/landing/:channel_name',
        component: LandingPage,
        exact: true,
    },

    /**
     * portal
     */
    {
        path: '/property',
        component: Portal_property,
        exact: true,
    },
    {
        path: '/list/:sub_sec_cate_id',
        component: List,
        exact: true,
    },
    /** 自行鉴权 */
    {
        path: '/detail/:sec_id',
        component: Detail,
        exact: true,
    },
    // {
    //     path:
    //         '/preview_detail/:sec_id',
    //     component: Portal_product_preview_detail,
    //     exact: true,
    // },
];

export { ValidatingRoutesList, NonValidatingRoutesList };
