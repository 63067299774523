import s from './index.module.scss';
import { ReqImg } from '../../official';
import tecImg1 from '../../../../images/tecImg1-mobile.png';
import tecImg2 from '../../../../images/tecImg2-mobile.png';
import tecImg0 from '../../../../images/tecImg0-mobile.png';

import tecImgEn1 from '../../../../images/tecImg1-mobileen.png';
import tecImgEn2 from '../../../../images/tecImg2-mobileen.png';
import tecImgEn0 from '../../../../images/tecImg0-mobileen.png';

import service1 from '../../../../images/service1.png';
import service2 from '../../../../images/service2.png';
import service3 from '../../../../images/service3.png';
import fzh from '../../../../images/black_fz.png';
import investment1 from '../../../../images/investment1.png';
import investment2 from '../../../../images/investment2.png';
import { useTranslation } from 'react-i18next';
import misc from '../../../../services/misc';
import { useState, useEffect, useRef, useMemo } from 'react';
import HomeTitle from '../HomeTitle-mobile';
import star from '../../../../images/support_star.png';

const Customcollapse = props => {
    const { children, title, subtitle, nonePadding } = props;
    const content = useRef(null);
    const { i18n } = useTranslation();

    const [active, setActive] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);
    useEffect(() => {
        const childHeightRaw = content.current.clientHeight;
        setContentHeight(`${childHeightRaw / 16}rem`);
    }, [content]);
    return (
        <div className={`support-collapse ${i18n.language === 'en' ? 'support-collapse-en' : ''}`}>
            <div className="support-collapse-header" onClick={() => setActive(!active)}>
                {i18n.language !== 'en' && (
                    <>
                        <div className="support-collapse-header-title">{title}</div>
                        <div className="support-collapse-header-divider" />
                        <div className="support-collapse-header-subtitle">{subtitle}</div>
                    </>
                )}
                {i18n.language === 'en' && (
                    <div className="support-collapse-header-en">
                        <div className="support-collapse-header-title">{title}</div>
                        <div className="support-collapse-header-subtitle">{subtitle}</div>
                    </div>
                )}
                <div className="arrow-container">
                    <img
                        src={ReqImg('./mobile_images/ic_global_arrow_down.svg')}
                        alt="Panel Arrow"
                        className="arrow"
                        style={{
                            transform: active && 'rotate(180deg)',
                        }}
                    />
                </div>
            </div>
            <div
                className={`support-collapse-content ${
                    nonePadding ? 'support-collapse-content-none' : ''
                }`}
                style={{
                    maxHeight: active ? contentHeight : 0,
                }}
            >
                <div ref={content}>{children}</div>
            </div>
        </div>
    );
};
const Support = () => {
    const [tecIndex, setTecIndex] = useState(0);
    const { t, i18n } = useTranslation();

    const buttonArray = useMemo(
        () => [
            t('support-technology-btn1'),
            t('support-technology-btn2'),
            t('support-technology-btn3'),
        ],
        [t],
    );
    const serviceArray = useMemo(
        () => [
            {
                icon: service1,
                title: t('support-sevices-item1-title'),
                desc: [
                    {
                        title: '健康生活',
                        icon: '',
                    },
                    {
                        title: '极客体验',
                        icon: '',
                    },
                ],
            },
            {
                icon: service2,
                title: t('support-sevices-item2-title'),
                desc: [
                    {
                        title: '信托',
                        icon: '',
                    },
                    {
                        title: '税筹',
                        icon: '',
                    },
                    {
                        title: '身份规划',
                        icon: '',
                    },
                ],
            },
            {
                icon: service3,
                title: t('support-sevices-item3-title'),
                desc: [
                    {
                        title: '公司商务',
                        icon: '',
                    },
                    {
                        title: '娱乐生活',
                        icon: '',
                    },
                    {
                        title: '家政助理',
                        icon: '',
                    },
                    {
                        title: '子女教育',
                        icon: '',
                    },
                ],
            },
        ],
        [t],
    );

    const investmentArray = useMemo(
        () => [
            {
                title: t('support-investment-array-title1'),
                desc: t('support-investment-array-desc1'),
            },
            {
                title: t('support-investment-array-title2'),
                desc: t('support-investment-array-desc2'),
            },
            {
                title: t('support-investment-array-title3'),
                desc: t('support-investment-array-desc3'),
            },
            {
                title: t('support-investment-array-title4'),
                desc: t('support-investment-array-desc4'),
            },
        ],
        [t],
    );
    const tecArray = useMemo(
        () => [
            {
                title: t('support-technology-btn1'),
                subTitle: t('support-technology-desc1'),
            },
            {
                title: t('support-technology-btn2'),
                subTitle: t('support-technology-desc2'),
            },
            {
                title: t('support-technology-btn3'),
                subTitle: t('support-technology-desc3'),
            },
        ],
        [t],
    );
    const currentImg = useMemo(() => {
        if (i18n.language === 'en') {
            return tecIndex === 0 ? tecImgEn0 : tecIndex === 1 ? tecImgEn1 : tecImgEn2;
        }
        return tecIndex === 0 ? tecImg0 : tecIndex === 1 ? tecImg1 : tecImg2;
    }, [i18n.language, tecIndex]);
    return (
        <>
            <div className={`${s.content} ${i18n.language === 'en' ? s.contentEn : ''}`}>
                <span className="nav-anchor" id="business"></span>
                <HomeTitle title={t('support-sevices-title')}></HomeTitle>
                {i18n.language === 'en' && (
                    <div className={s.titleDesc}>
                        Advocate the ultimate transparency, break the information gap with open
                        source ideas; Emphasis on customer involvement, accompany tech entrepreneurs
                        at various stages from wealth freedom to self-realization.
                    </div>
                )}
                <Customcollapse
                    title={t('support-sevices-title')}
                    subtitle={t('support-sevices-subtitle')}
                >
                    <div className={s.container1}>
                        {serviceArray.map((item, index) => {
                            return (
                                <div key={index} className={s.cardItem}>
                                    <div className={s.header}>
                                        <img src={star} alt="" className={s.cardIcon} />
                                        <div className={s.sp}></div>
                                    </div>
                                    <div className={s.titleContent}>
                                        {item.desc.map((descItem, descIndex) => {
                                            return (
                                                <div className={s.iconD} key={descIndex}>
                                                    <div className={s.icon}>
                                                        <img
                                                            src={
                                                                require(`../../../../images/ic-service-${index}${
                                                                    descIndex + 1
                                                                }.png`).default
                                                            }
                                                        ></img>
                                                    </div>
                                                    <div className={s.text}>{descItem.title}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Customcollapse>
                <Customcollapse
                    title={
                        i18n.language === 'en' ? 'Our Investment' : t('support-investment-title')
                    }
                    subtitle={t('support-investment-des')}
                >
                    <div className={s.container2}>
                        <div className={s.cardContent}>
                            <div className={s.cardLeft}>
                                <div className={s.cardLeftTopLine}></div>
                                <div className={s.cardLeftTop}>{t('support-investment-cio')}</div>
                                <div className={s.cardLeftTopOne}>
                                    {t('support-investment-cio')}
                                </div>
                            </div>
                            <div className={s.cardLeftBottom}>
                                <img src={fzh} alt="" className={s.cardLeftBottomIcon} />
                                <div className={s.cardLeftBottomRight}>
                                    <div className={s.cardLeftBottomName}>
                                        {t('support-investment-cio-name')}
                                    </div>
                                    <div className={s.cardLeftBottomTitle}>
                                        {t('support-investment-cio-title')}
                                    </div>
                                </div>
                            </div>
                            <div className={s.cardRight}>
                                <div className={s.cardRightTop}>
                                    <div className={s.cardRightTopLeft}>
                                        <img
                                            src={investment1}
                                            alt=""
                                            className={s.cardRightTopLeftIcon}
                                        />
                                        <div>
                                            <div className={s.cardRightTopOne}>
                                                {t('support-investment-target1-mobile')}
                                            </div>
                                            <div className={s.cardRightTopTow}>
                                                {t('support-investment-target1-mobile-d')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.cardRightTopLeft}>
                                        <img
                                            src={investment2}
                                            alt=""
                                            className={s.cardRightTopLeftIcon}
                                        />
                                        <div>
                                            <div className={s.cardRightTopOne}>
                                                {t('support-investment-target2-mobile')}
                                            </div>
                                            <div className={s.cardRightTopTow}>
                                                {t('support-investment-target2-mobile-d')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {investmentArray.map((item, index) => {
                                    return (
                                        <div className={s.cardRightBottomItem} key={index}>
                                            <div className={s.cardRightBottomItemTop}>
                                                <div className={s.block}></div>
                                                {item.title}
                                            </div>
                                            <div className={s.cardRightBottomItemDesc}>
                                                {item.desc}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Customcollapse>
                <Customcollapse
                    title={t('support-technology-title')}
                    subtitle={t('support-technology-des')}
                    nonePadding
                >
                    <div className={s.container3}>
                        <div className={s.cardLeft}>
                            <div className={s.cardLeftTopLine}></div>
                            <div className={s.cardLeftTop}>{tecArray[tecIndex].title}</div>
                            <div className={s.cardLeftTopOne}>{tecArray[tecIndex].title}</div>
                        </div>
                        <div className={s.subTitle}>{tecArray[tecIndex].subTitle}</div>
                        <img src={currentImg} alt="" />

                        <div className={s.button}>
                            {buttonArray.map((item, index) => {
                                return (
                                    <div
                                        className={`${s.buttonItem} ${
                                            tecIndex === index ? s.selectButton : ''
                                        }`}
                                        onMouseEnter={() => {
                                            misc.eventReport([
                                                {
                                                    event_type: 2,
                                                    event_id: 30022,
                                                    params: { title: item },
                                                    ts: +new Date(),
                                                    sid: '',
                                                },
                                            ]);
                                            setTecIndex(index);
                                        }}
                                        key={index}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Customcollapse>
            </div>
        </>
    );
};
export default Support;
