import axios from '../utils/o-axios';
const isMoblie = () => {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
};
class Misc {
    getSmsCode = data => {
        return axios.post('/v1/misc/send_cc', {
            data,
        });
    };

    eventReport = parmas => {
        let data = parmas;
        if (Array.isArray(data)) {
            let from = isMoblie() ? 'h5' : 'pc';
            if (isWeiXin()) {
                from = 'weixin';
            }

            data = parmas.map(item => ({ ...item, from: from }));
        }
        return axios.post('/v2/misc/event_report', {
            data,
        });
    };
}
const isWeiXin = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    const match = ua.match(/MicroMessenger/i);
    if (match === null) {
        return false;
    }
    if (match.includes('micromessenger')) {
        return true;
    }
    return false;
};
export default new Misc();
