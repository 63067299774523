import axios from 'axios';
import { setting } from '../config';
import { v4 as uuid } from 'uuid';
import store from '../store/store';
import NProgress from 'nprogress';
const REPORT_URL = '/v2/misc/event_report';
import cookie from 'react-cookies';

NProgress.configure({ showSpinner: false });

export const checkIsTokenExisted = () => {
    let hasToken = false;
    const login = cookie.load('login') ?? {};
    console.log(login);
    debugger;
    /** if has token */
    if (Object.keys(login).length > 0 && login.token) {
        hasToken = true;
    }

    return {
        hasToken,
    };
};
const deviceString = deviceJudge();
// 设备类型
function deviceJudge() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        //苹果端
        return 'ios';
    } else if (/(Android)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        //安卓端
        return 'android';
    } else {
        //pc端
        return 'pc';
    }
}
function Axios() {
    const instance = axios.create({
        // timeout: 50000,
        baseURL: setting.host,
    });

    /** 所有接口都为POST,后续有get需要改配置 */
    const addBasicConfig = config => {
        const login = cookie.load('login') ?? {};
        const state = store.getState();
        /** add req_id & local data */
        config.data = {
            ...config.data,
            req_id: uuid().slice(0, 16),
            local: {
                client_type: setting.client_type,
                device: deviceString,
                locale: window.localStorage.getItem('lk_lang') === 'en' ? 'en-US' : 'zh-CN',
            },
        };
        /** if has token */
        if (Object.keys(login).length > 0) {
            config.data = {
                ...config.data,
                login: login,
            };
        }
    };

    // backend interface docs
    instance.interceptors.request.use(
        config => {
            /** 打点不展示progress进度条 */
            if (config.url !== REPORT_URL) {
                NProgress.start();
            }

            /** add req_id & local data */
            addBasicConfig(config);
            return config;
        },
        err => {
            return Promise.reject(err);
        },
    );

    instance.interceptors.response.use(
        response => {
            if (response.config?.url !== REPORT_URL) {
                NProgress.done();
            }

            const code = response.data.code;
            /** 如果不存在，强制跳转login */
            if (code === -11 || code === -10 || code === -9) {
                localStorage.removeItem('login');
                window.location.pathname = 'login';

                return Promise.reject(response.data);
            }

            if (code !== 0) {
                return Promise.reject(response.data);
            } else {
                return response.data;
            }
        },
        error => {
            NProgress.done();
            return Promise.reject(error);
        },
    );

    return instance;
}

export default new Axios();
